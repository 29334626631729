export const getters = {
    cartItems: (state) => state.cartItems,
    // Es gibt insgesamt (state, getters, rootState, rootGetters) und wenn mein ein Attribut angeben MUSS aber es nicht verwendet makiert man es mit "_"
    cartTotal: (_, getters, __, rootGetters) => {
        const cartItems = getters.cartItems;
        return cartItems.reduce((sum, cartItem) => {
            const product = rootGetters.product(cartItem.productId);
            return sum + product.price;

        }, 0);
    },
};